<template>
  <div class="table-container">
    <!-- table title -->
    <div class="table-title" :class="{ 'with-description': withDescription }">
      <slot name="title" />
    </div>

    <div v-show="withDescription" class="account-context">
      <slot name="description" />
    </div>

    <!-- table -->
    <el-row :gutter="20">
      <el-col :span="24">
        <slot />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'BlueTableWrapper',
  props: {
    withDescription: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
.table-title.with-description {
  margin-bottom: 16px;
}
.account-context {
  // margin-top: 4px;
  margin-bottom: 16px;
}
</style>
