<template>
  <BlueTableWrapper withDescription>
    <template #title>
      {{ $t('promotion.xmasMakeAWish.transactionHistory') }}
    </template>

    <template #description>
      <div class="account-text">{{ $t('promotion.xmasMakeAWish.field.accountID') }}: {{ account }}</div>
      <div class="title-desc">{{ $t('promotion.xmasMakeAWish.transactionHistoryDesc') }}</div>
    </template>

    <el-table :data="displayData" class="head-gutter" :height="displayData.length > 1 ? 240 : 120">
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.date')" min-width="170">
        <template slot-scope="scope">
          {{ scope.row.date }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.type')" min-width="170">
        <template slot-scope="scope">{{
          scope.row.type === '-' ? scope.row.type : $t(`promotion.xmasMakeAWish.tableContent.${scope.row.type}`)
        }}</template>
      </el-table-column>
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.deposit')" min-width="170">
        <template slot-scope="scope"> {{ scope.row.deposit | formatNumber }}</template>
      </el-table-column>
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.tradingLots')" :min-width="adjustTalbeColumnWidth">
        <template slot-scope="scope"> {{ scope.row.tradingLots }}</template>
      </el-table-column>
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.prizeWon')" min-width="272">
        <template slot-scope="scope">{{ scope.row.prizeWon || 'N/A' }}</template>
      </el-table-column>
    </el-table>
  </BlueTableWrapper>
</template>

<script>
import BlueTableWrapper from '@/components/promotion/common/BlueTableWrapper.vue';

export default {
  name: 'TransactionTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    account: {
      type: String,
      default: ''
    }
  },
  components: { BlueTableWrapper },
  computed: {
    displayData() {
      const defaultData = [
        {
          date: '-',
          type: '-',
          deposit: '-',
          tradingLots: '-',
          prizeWon: '-'
        }
      ];

      return this.tableData.length ? [...this.tableData] : defaultData;
    },
    adjustTalbeColumnWidth() {
      const lang = this.$store.state.common.lang;
      if (lang === 'pt' || lang === 'es' || lang === 'ar') {
        return 220;
      }
      return 170;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
/deep/ .el-table.head-gutter td {
  border-bottom: 1px solid #ebeef5;
}

.table-title.with-account {
  margin-bottom: 8px;
}

.account-text {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 900;
  color: $black;
}

.title-desc {
  text-transform: none;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 20px;
  color: $black;
}
</style>
