<template>
  <GeneralDialog :visible.sync="showDialog" :visibleHeader="true" :needFullscreen="true" :hideChat="true">
    <!-- lucky draw transaction -->
    <div v-if="luckyDrawType === 'confirm'">
      <div class="result-title">{{ $t('promotion.xmasMakeAWish.dialog.luckyDrawTransaction') }}</div>
      <!-- table -->
      <div class="transaction-container">
        <el-table :data="displayData" max-height="128">
          <el-table-column min-width="128">
            <template slot-scope="scope">
              <div class="text-left">{{ scope.row.field }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('promotion.xmasMakeAWish.field.luckyDrawDeposit')" min-width="128">
            <template slot-scope="scope">{{ scope.row.deposit }}</template>
          </el-table-column>
          <el-table-column :label="$t('promotion.xmasMakeAWish.field.tradingLots')" min-width="128">
            <template slot-scope="scope">{{ scope.row.tradingLots }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- xmas gifts -->
    <div v-else>
      <div class="result-title">{{ $t('promotion.xmasMakeAWish.dialog.xmasGift') }}</div>
      <div class="result-prize">{{ prize }}</div>
    </div>

    <div class="button-box">
      <el-button class="btn-blue" data-testid="doLuckyDraw" @click="actionFunc">
        {{ $t('common.button.confirm') }}
      </el-button>
    </div>
  </GeneralDialog>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import ROUNDING from '@/util/rounding';

export default {
  name: 'LuckyDrawDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    currentPrize: {
      type: Object,
      default: () => {}
    },
    currentDepositLotsData: {
      type: Object,
      default: () => {}
    },
    luckyDrawType: {
      type: String,
      default: ''
    },
    prize: {
      type: String,
      default: ''
    }
  },
  components: { GeneralDialog },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    displayData() {
      return [
        {
          field: this.$t('promotion.xmasMakeAWish.button.redeemed'),
          deposit: `-${this.currentPrize.deposit}`,
          tradingLots: `-${this.currentPrize.tradingLots}`
        },
        {
          field: this.$t('promotion.xmasMakeAWish.field.current'),
          deposit: ROUNDING(a => a, Number(this.currentDepositLotsData.deposit) - Number(this.currentPrize.deposit), 2),
          tradingLots: ROUNDING(
            a => a,
            Number(this.currentDepositLotsData.lots) - Number(this.currentPrize.tradingLots),
            1
          )
        }
      ];
    }
  },
  watch: {
    show(bool) {
      this.showDialog = bool;
    },
    showDialog(bool) {
      this.updateShowDialog(bool);
    }
  },
  methods: {
    updateShowDialog(bool) {
      this.$nextTick(() => {
        this.$emit('update:show', bool);
      });
    },
    actionFunc() {
      if (this.luckyDrawType === 'confirm') {
        this.$emit('onStartLuckyDraw');
      } else {
        this.showDialog = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dialog/conditionDialog.scss';

.result-title {
  font-size: 22px;
  font-weight: 900;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: $black;
  margin-bottom: 28px;
}
.result-prize {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  text-align: center;
  color: $dark-gray;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.transaction-container {
  margin-bottom: 16px;
}

// table style
/deep/ .el-table {
  th {
    height: auto;
    background-color: $white;
    color: $black;
    font-weight: 900;
    .cell {
      padding: 0;
    }
  }

  td {
    height: auto;
    border-bottom: none;
  }
}
/deep/ .el-table th {
  height: auto;
  .cell {
    padding: 0;
  }
}

.text-left {
  text-align: left;
}

// dialog
/deep/ .el-dialog__body {
  padding: 0 20px !important;

  .dialog-body {
    padding: 28px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

@media (min-width: 550px) {
  /deep/ .el-dialog__body {
    .dialog-body {
      padding: 80px 40px 64px 40px;
    }
  }

  .result-title {
    font-size: 28px;
  }
}

@media (min-width: 767px) {
  /deep/ .el-dialog__body {
    padding: 0 20px !important;
  }
}
</style>
