<template>
  <AgreeTncDialog
    :agreeTncVisible.sync="visible"
    :headerText="headerText"
    :accountData="accountData"
    @activateSuccess="activateSuccess"
  >
    <li>{{ $t('promotion.xmasMakeAWish.dialog.tncContext.tnc1') }}</li>
    <li>{{ $t('promotion.xmasMakeAWish.dialog.tncContext.tnc2') }}</li>
    <li>{{ $t('promotion.xmasMakeAWish.dialog.tncContext.tnc3') }}</li>
    <li>{{ $t('promotion.xmasMakeAWish.dialog.tncContext.tnc4') }}</li>
    <li>{{ $t('promotion.xmasMakeAWish.dialog.tncContext.tnc5') }}</li>
  </AgreeTncDialog>
</template>

<script>
import AgreeTncDialog from '../common/AgreeTncDialog.vue';

export default {
  props: {
    agreeTncVisible: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: ''
    },
    accountData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    AgreeTncDialog
  },
  data() {
    return {
      visible: this.agreeTncVisible
    };
  },
  watch: {
    agreeTncVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateAgreeTncVisible(bool);
    }
  },
  methods: {
    activateSuccess() {
      this.$emit('activateSuccess');
    },
    updateAgreeTncVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:agreeTncVisible', bool);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .tnc {
  margin-top: 60px;
  margin-bottom: 56px;
}
</style>
