<template>
  <div class="cb-dialog">
    <ConditionDialog
      :visible.sync="open"
      :isSuccess="cbType === 'success' || cbType === 'redeemCreditAndVoucher'"
      :dialogHeightAuto="cbType === 'failNotOptout'"
      @closCbDialog="closCbDialog"
    >
      <template #content>
        <p v-if="cbType === 'success'" class="text-congratuate">
          {{ $t('promotion.xmasMakeAWish.message.congratulate') }}
        </p>
        <i18n class="text-result" :class="[cbType]" :path="getI18nContextPath()" tag="div" />
        <el-button v-if="cbType === 'failNotOptout'" class="btn-blue submit-btn" @click="sureToOptOut">
          {{ $t('common.button.confirm') }}
        </el-button>
      </template>
    </ConditionDialog>
  </div>
</template>

<script>
import ConditionDialog from '@/components/dialog/ConditionDialog.vue';

export default {
  name: 'CallbackDialog',
  components: {
    ConditionDialog
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    callbackType: {
      type: String,
      require: true // success, failNotOptout, failOptout, redeemCreditAndVoucher
    }
  },
  data() {
    return {
      open: false,
      cbType: null
    };
  },
  watch: {
    show(val) {
      this.open = val;
    },
    open(bool) {
      this.handleClose(bool);
    },
    callbackType: {
      immediate: true,
      handler(newValue) {
        this.cbType = newValue;
      }
    }
  },
  methods: {
    getI18nContextPath() {
      switch (this.cbType) {
        case 'success':
          return `promotion.xmasMakeAWish.message.congratulateContext`;
        case 'failNotOptout':
          return `promotion.xmasMakeAWish.message.signedOutPromo`;
        case 'failOptout':
          return `promotion.xmasMakeAWish.message.deactivePromo`;
        case 'redeemCreditAndVoucher':
          return `promotion.xmasMakeAWish.message.redeemAmount`;
        default:
          return '';
      }
    },
    handleClose(bool) {
      this.$nextTick(() => {
        this.$emit('update:show', bool);
      });
    },
    sureToOptOut() {
      this.$emit('optout', true);
    },
    closCbDialog() {
      this.$emit('closCbDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.text-result {
  margin-top: 24px;
  margin-bottom: 24px;
  color: $black;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;

  &.success {
    padding: 0 14%;
  }
  &.failOptout {
    padding: 0 10%;
  }
}

.common-dialog-wrapper /deep/ .dialog {
  .el-dialog__body {
    padding: 0 68px;
    .dialog-body {
      padding: 0 0 45px 0;
      max-height: 310px;
    }
  }
}

@media (max-width: 767px) {
  .common-dialog-wrapper /deep/ .dialog {
    .el-dialog__body {
      padding: 30px 20px;
    }
  }
}
</style>
