<template>
  <div class="desc-container">
    <p class="desc-title">{{ title }}</p>
    <ol v-if="!useSlotForLiTag" class="desc-context">
      <li v-for="(description, index) in descriptions" :key="index">
        {{ `${description}` }}
      </li>
    </ol>
    <ol v-else class="desc-context">
      <slot />
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    title: {
      type: String,
      default: ''
    },
    descriptions: {
      type: Array,
      default: () => []
    },
    useSlotForLiTag: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.desc-container {
  text-align: start !important;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 1.5;
  margin-bottom: 24px;
}
.desc-title {
  font-weight: bold;
  margin-bottom: 4px;
}
.desc-context {
  margin-left: 16px;
  li {
    list-style: decimal;
    font-size: 14px;
    line-height: 1.8;
  }
}
</style>
