<template>
  <BlueTableWrapper>
    <template #title>
      {{ $t('promotion.xmasMakeAWish.giftRedemption') }}
    </template>

    <div class="title-container">
      <div
        v-for="(item, index) in giftRedemptionContext"
        :key="index"
        class="title-desc"
        :class="{ last: index === 1 }"
      >
        {{ item }}
      </div>
    </div>

    <el-table :data="displayData" class="head-gutter">
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.creditBonus')" min-width="240">
        <template slot-scope="scope">
          <div class="reward-container center" :style="{ height: `${physicalRedeemHeightCount * 60}px` }">
            <div class="amount">${{ scope.row.creditBonus ? scope.row.creditBonus : '-' }}</div>
          </div>
          <el-button
            class="btn-blue capitalize redeem-btn"
            :class="{ redeemed: Number(canCreditRedeem) === 1 }"
            data-testid="creditRedeem"
            :disabled="canCreditRedeem < 2"
            @click="redeemActions('CB')"
          >
            {{ getRedeemButtonText(canCreditRedeem) }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.cashVoucher')" min-width="240">
        <template slot-scope="scope">
          <div class="reward-container center" :style="{ height: `${physicalRedeemHeightCount * 60}px` }">
            <div class="amount">${{ scope.row.cashVoucher ? scope.row.cashVoucher : '-' }}</div>
          </div>
          <el-button
            class="btn-blue capitalize redeem-btn"
            :class="{ redeemed: Number(canVoucherRedeem) === 1 }"
            data-testid="voucherRedeem"
            :disabled="canVoucherRedeem < 2"
            @click="redeemActions('CV')"
          >
            {{ getRedeemButtonText(canVoucherRedeem) }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('promotion.xmasMakeAWish.field.physicalRewards')" min-width="496">
        <template slot-scope="scope">
          <div class="reward-container overflow-y-auto">
            <div v-if="scope.row.physicalRewards && scope.row.physicalRewards.length">
              <div v-for="(item, index) in scope.row.physicalRewards" :key="index" class="rewards">
                {{ item }}
              </div>
            </div>
            <div v-else class="gift-physical-no-data">-</div>
          </div>
          <div class="hide-block"></div>
        </template>
      </el-table-column>
    </el-table>
  </BlueTableWrapper>
</template>

<script>
import BlueTableWrapper from '@/components/promotion/common/BlueTableWrapper.vue';
import { apiXMakeAWishRedeem } from '@/resource';
import { isSuccess } from '@/util/restApi';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'GiftRedemptionTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      giftRedemptionContext: this.$t('promotion.xmasMakeAWish.giftRedemptionDesc')
    };
  },
  components: {
    BlueTableWrapper
  },
  computed: {
    ...mapState('promotion/xmasMakeAWish', ['canCreditRedeem', 'canVoucherRedeem', 'participantStatus']),
    ...mapGetters('promotion/xmasMakeAWish', ['campaignID']),
    displayData() {
      return [...this.tableData];
    },
    physicalRedeemHeightCount() {
      return this.displayData[0]?.physicalRewards && this.displayData[0]?.physicalRewards.length
        ? Number(this.displayData[0].physicalRewards.length)
        : 1;
    }
  },
  methods: {
    ...mapActions('promotion/xmasMakeAWish', [
      'actionSetCallbackStatus',
      'actionSetShowResultDialog',
      'actionFetchCampaignInfo'
    ]),
    getRedeemButtonText(status) {
      // 0: notAllow, 1: alreadyRedeemed, 2: canRedeem
      return Number(status) === 1
        ? this.$t('promotion.xmasMakeAWish.button.redeemed')
        : this.$t('common.button.redeem');
    },
    async redeemActions(type) {
      // CB: redeem, CV: voucher
      try {
        const { data: result } = await apiXMakeAWishRedeem({
          campaignId: this.campaignID,
          type
        });

        if (isSuccess(result)) {
          this.actionSetCallbackStatus('redeemCreditAndVoucher');
          this.actionSetShowResultDialog(true);
        } else {
          this.$message({
            message: this.$t('common.field.failed'),
            type: 'error',
            duration: 3000
          });
        }
      } catch (error) {
        this.$message({
          message: this.$t('common.field.failed'),
          type: 'error',
          duration: 3000
        });
      } finally {
        this.actionFetchCampaignInfo(this.campaignID);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
.amount {
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
}
.rewards {
  height: 60px;
  border-bottom: 1px solid #f3f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-container {
  max-height: 200px;
  min-height: 60px;
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.overflow-y-auto {
    overflow-y: auto;
  }
}
.redeem-btn {
  width: 150px;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  &.redeemed {
    font-weight: 800;
    color: $dark-gray;
  }
}

.gift-physical-no-data {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-block {
  width: 150px;
  height: 40px;
}

.title-container {
  .title-desc {
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 4px;
    color: $black;
    &:last-child {
      margin-bottom: 20px;
    }
  }
}
</style>
