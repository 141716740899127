<template>
  <div class="meta-box">
    <div class="title-container">
      <h3 class="title">
        {{
          getOptParticipantStatus === 'alreadyOptIn'
            ? $t('promotion.xmasMakeAWish.deactive.title')
            : $t('promotion.xmasMakeAWish.active.title')
        }}
      </h3>
    </div>

    <!-- action btn -->
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div class="sub-title" :class="{ inactive: getOptParticipantStatus === 'alreadyOptIn' }">
          {{
            getOptParticipantStatus === 'alreadyOptIn'
              ? $t('promotion.xmasMakeAWish.deactive.desc')
              : $t('promotion.xmasMakeAWish.active.desc')
          }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button
          v-if="getOptParticipantStatus === 'alreadyOptIn'"
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="onSignOut"
        >
          {{ $t('promotion.xmasMakeAWish.button.deactive') }}
        </el-button>
        <el-button
          v-else
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="onActivateCampaign"
          :disabled="getOptParticipantStatus === 'alreadyOptOut'"
        >
          {{ $t('promotion.xmasMakeAWish.button.active') }}
        </el-button>
      </el-col>
    </el-row>

    <!-- description -->
    <el-row :gutter="20" class="sub-box2">
      <!-- how to claim -->
      <el-col :xs="24" v-if="getOptParticipantStatus === 'availableForOptIn'">
        <Description
          :title="$t('promotion.xmasMakeAWish.claimTitle')"
          :descriptions="$t('promotion.xmasMakeAWish.claimDescriptions')"
        />
      </el-col>
      <!-- lucky draw -->
      <el-col :xs="24" v-if="canSpin > 0 && optIn">
        <LuckyDraw
          :startLuckyDraw="startLuckyDraw"
          :canSpin="canSpin"
          :currentPrize="currentPrize"
          :currentDepositLotsData="currentDepositLotsData"
          @openLuckyDrawDialog="openLuckyDrawDialog"
          @luckyDrawOver="luckyDrawOver"
        />
      </el-col>
      <el-col :xs="24" class="button-box">
        <el-button
          class="el-button btn-blue long-button"
          data-testid="fund"
          @click="goToFund"
          :disabled="getOptParticipantStatus === 'alreadyOptOut' || !optIn"
        >
          {{ $t('promotion.xmasMakeAWish.button.fund') }}
        </el-button>
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.xmasMakeAWish.moreDetail">
          <template v-slot:prize>
            <a @click="showPrizeList = !showPrizeList" data-testid="prize">
              {{ $t('promotion.xmasMakeAWish.prize') }}
            </a>
          </template>
        </i18n>
      </el-col>

      <!-- eligibility -->
      <el-col :xs="24">
        <Description
          :title="$t('promotion.xmasMakeAWish.eligibilityTitle')"
          :descriptions="$t('promotion.xmasMakeAWish.eligibleDescriptions')"
        />
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.xmasMakeAWish.tnc">
          <template v-slot:link>
            <a :href="tncLink" target="_blank" data-testid="tnc">
              {{ $t('promotion.xmasMakeAWish.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>

    <!-- dialog -->
    <AccountDialog
      :dialogVisible.sync="showAccountDialog"
      :campaignID="campaignID"
      :accountOptions="accountOptions"
      @sendAccountMt4Dto="closeAndOpenTncDialog"
      @close="onCloseAccountDialog"
    />
    <AgreeTnc
      :agreeTncVisible.sync="showAgreeTnc"
      headerText="promotion.xmasMakeAWish.dialog.tnc"
      :accountData="accountData"
      @activateSuccess="onActivateSuccess"
    />
    <PrizeListDialog :prizeListVisible.sync="showPrizeList" />
    <LuckyDrawDialog
      :show.sync="showLuckyDrawDialog"
      :currentPrize="currentPrize"
      :currentDepositLotsData="currentDepositLotsData"
      :luckyDrawType="luckyDrawType"
      :prize="prize"
      @onStartLuckyDraw="onStartLuckyDraw"
    />
  </div>
</template>

<script>
import { apiGetRedeemableAccounts } from '@/resource';
import { isSuccess } from '@/util/restApi';
import blackList from '@/mixins/blackList';
import Description from '@/components/description/DescriptionV2.vue';
import AccountDialog from './AccountDialog.vue';
import AgreeTnc from './AgreeTnc.vue';
import LuckyDraw from './LuckyDraw.vue';
import PrizeListDialog from './PrizeListDialog.vue';
import LuckyDrawDialog from './LuckyDrawDialog.vue';

import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'FundAccount',
  components: {
    Description,
    AccountDialog,
    AgreeTnc,
    LuckyDraw,
    PrizeListDialog,
    LuckyDrawDialog
  },
  mixins: [blackList],
  data() {
    return {
      showAccountDialog: false,
      showAgreeTnc: false,
      accountData: null,
      showPrizeList: false,
      redeemableAccounts: []
    };
  },
  computed: {
    ...mapState('promotion/xmasMakeAWish', [
      'optIn',
      'canSpin',
      'currentPrize',
      'currentDepositLotsData',
      'showResultDialog',
      'callbackStatus',
      'luckyDrawType',
      'startLuckyDraw',
      'prize'
    ]),
    ...mapGetters('promotion/xmasMakeAWish', ['campaignID', 'getOptParticipantStatus']),
    showLuckyDrawDialog: {
      get() {
        return this.$store.state.promotion.xmasMakeAWish.showLuckyDrawDialog;
      },
      set(newValue) {
        this.actionSetShowLuckyDrawDialog(newValue);
      }
    },
    lang() {
      return this.$store.state.common.lang;
    },
    accountOptions() {
      return this.redeemableAccounts.map(({ mt4Account, currency, datasourceId, accountDisplayType }) => {
        let accountType = this.$config.accountTypeMaps[Number(accountDisplayType)] || null;
        let label = accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`;
        return {
          label,
          value: {
            mt4Account,
            currency,
            datasourceId
          }
        };
      });
    },
    tncLink() {
      return `https://${this.GLOBAL_REFERRAL_SITE}/pdf/0528/Xmas_Make_A_Wish_Terms_and_Conditions.pdf`;
    }
  },
  watch: {
    showLuckyDrawDialog: {
      immediate: true,
      handler(newValue) {
        if (!newValue) this.actionSetPrize('');
      }
    }
  },
  methods: {
    ...mapActions('promotion/xmasMakeAWish', [
      'actionFetchCampaignInfo',
      'actionSetShowResultDialog',
      'actionSetCallbackStatus',
      'actionClosCbDialog',
      'actionSetStartLuckyDraw',
      'actionSetShowLuckyDrawDialog',
      'actionSetLuckyDrawType',
      'actionSetPrize'
    ]),
    async refreshEligibleCampaigns() {
      await this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    onCloseAccountDialog() {
      this.showAccountDialog = false;
    },
    goToFund() {
      this.getDepositBlacklist();
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    async onActivateCampaign() {
      try {
        const { data: cbData } = await apiGetRedeemableAccounts(this.campaignID);
        if (isSuccess(cbData)) {
          this.redeemableAccounts = cbData.data;
          this.showAccountDialog = true;
        } else {
          this.actionClosCbDialog();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    closeAndOpenTncDialog(accountMt4Dto) {
      this.accountData = { ...accountMt4Dto, campaignID: this.campaignID };
      this.showAccountDialog = false;
      setTimeout(() => {
        this.showAgreeTnc = true;
      }, 100);
    },
    async onActivateSuccess() {
      try {
        await this.refreshEligibleCampaigns();
        this.actionSetCallbackStatus('success');
        this.actionSetShowResultDialog(true);
        this.$emit('activeOptIn');
      } catch (error) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    onSignOut() {
      this.actionSetCallbackStatus('failNotOptout');
      this.actionSetShowResultDialog(true);
    },
    closeAllDialog() {
      this.showAccountDialog = false;
      this.showAgreeTnc = false;
      this.actionClosCbDialog();
    },
    // luckydraw functions
    onStartLuckyDraw() {
      this.actionSetShowLuckyDrawDialog(false);
      this.actionSetStartLuckyDraw(true);
    },
    openLuckyDrawDialog(type = '') {
      this.actionSetLuckyDrawType(type);
      this.actionSetShowLuckyDrawDialog(true);
    },
    async luckyDrawOver(payload) {
      this.actionSetStartLuckyDraw(false);
      if (payload === 'success') {
        this.openLuckyDrawDialog();
      }
      await this.actionFetchCampaignInfo(this.campaignID);
    }
  }
};
</script>
