<template>
  <BlueTableWrapper>
    <template #title>
      {{ $t('promotion.xmasMakeAWish.currentDepositLots') }}
    </template>

    <div class="small-table">
      <el-table :data="displayData" class="head-gutter">
        <el-table-column :label="$t('promotion.xmasMakeAWish.field.depositAmount')" width="240">
          <template slot-scope="scope"> ${{ scope.row.deposit }} </template>
        </el-table-column>
        <el-table-column :label="$t('promotion.xmasMakeAWish.field.lotsTraded')" width="240">
          <template slot-scope="scope"> {{ scope.row.lots }}</template>
        </el-table-column>
      </el-table>
    </div>
  </BlueTableWrapper>
</template>

<script>
import BlueTableWrapper from '@/components/promotion/common/BlueTableWrapper.vue';

export default {
  name: 'CurrentDepositLotsTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  components: { BlueTableWrapper },
  computed: {
    displayData() {
      return [...this.tableData];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
/deep/ .el-table.head-gutter td {
  border-bottom: 1px solid #ebeef5;
}

.table-title.with-account {
  margin-bottom: 16px;
}
.account-context {
  margin-top: 4px;
  margin-bottom: 16px;
}

.small-table {
  width: 100%;
  margin: auto;
}

@media (min-width: 537px) {
  .small-table {
    width: 480px;
    margin: auto;
  }
}
</style>
