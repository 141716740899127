<template>
  <div class="promotion">
    <GeneralDialog :visible.sync="visible" :visibleFooter="true" :needFullscreen="true" :hideChat="true">
      <div class="top">
        <div class="header-text">{{ $t('promotion.xmasMakeAWish.dialog.prizeList') }}</div>
      </div>
      <div class="table-container">
        <!-- table -->
        <el-row :gutter="20">
          <el-col :span="24">
            <div>
              <el-table :data="displayData" class="desc-table">
                <el-table-column min-width="145">
                  <template slot="header">
                    <div>
                      <div>
                        {{ $t('promotion.xmasMakeAWish.field.depositTradingVolume') }}
                      </div>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="deposit-container">
                      <p>{{ scope.row.deposit }}</p>
                      <p>{{ scope.row.lots }}</p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="145">
                  <template slot="header">
                    <div>
                      <div>
                        {{ $t('promotion.xmasMakeAWish.field.gifts') }}
                      </div>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.gifts" :key="index" class="gifts-container">
                      {{ item }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';

export default {
  props: {
    prizeListVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    GeneralDialog
  },
  data() {
    return {
      visible: false,
      displayData: [
        {
          deposit: `$200`,
          lots: `2`,
          gifts: [`$150 credit bonus`, `$5 Cash voucher`]
        },
        {
          deposit: `$500`,
          lots: `5`,
          gifts: [`$10 Cash voucher`, `$400 credit bonus`]
        },
        {
          deposit: `$1,000`,
          lots: `10`,
          gifts: [`$25 Amazon Prime Voucher`, `$25 Cash voucher`, `$800 credit bonus`]
        },
        {
          deposit: `$2,000`,
          lots: `25`,
          gifts: [`$50 Amazon Prime Voucher`, `$50 Cash voucher`, `$2,000 credit bonus`]
        },
        {
          deposit: `$4,000`,
          lots: `40`,
          gifts: [`Beats Flex – All-Day Wireless Earphones`, `$75 Amazon Prime Voucher`, `$70 Cash voucher`]
        },
        {
          deposit: `$8,000`,
          lots: `60`,
          gifts: [`HomePod Mini`, `$100 Amazon Prime Voucher`, `$100 Cash voucher`]
        },
        {
          deposit: `$12,000`,
          lots: `100`,
          gifts: [
            `AirPods (3rd generation) with MagSafe Charging Case`,
            `$150 Amazon Prime Voucher`,
            `$150 Cash voucher`
          ]
        },
        {
          deposit: `$25,000`,
          lots: `200`,
          gifts: [
            `Apple Watch SE GPS + Cellular Midnight Aluminum Case with Sport Band`,
            `AirTag Hermès Key Ring - Maison`,
            `$350 Amazon Prime Voucher`,
            `$350 Cash voucher`
          ]
        },
        {
          deposit: `$40,000`,
          lots: `350`,
          gifts: [`AirPods Max`, `$550 Amazon Prime Voucher`, `$550 Cash voucher`]
        },
        {
          deposit: `$60,000`,
          lots: `450`,
          gifts: [`iPad Air 256GB Wi-Fi + Cellular`, `$900 Amazon Prime Voucher`, `$900 Cash voucher`]
        },
        {
          deposit: `$90,000`,
          lots: `550`,
          gifts: [`iPhone 14 Pro 256GB`, `$1,000 Amazon Prime Voucher`, `$1,000 Cash voucher`]
        },
        {
          deposit: `$120,000`,
          lots: `800`,
          gifts: [
            `Latest M2 chip MacBook Air`,
            `Apple Watch Hermès Silver Stainless Steel Case with Single Tour Deployment Buckle`,
            `$1,500 Amazon Prime Voucher`,
            `$1,500 Cash voucher`
          ]
        },
        {
          deposit: `$230,000`,
          lots: `1,500`,
          gifts: [
            `Top configuration 24‑inch iMac with Apple M1 chip.`,
            `$2,500 Amazon Prime Voucher`,
            `$2,500 Cash voucher`
          ]
        },
        {
          deposit: `$330,000`,
          lots: `2,500`,
          gifts: [`Pro Display XDR`, `$5,000 Amazon Prime Voucher`, `$5,000 Cash voucher`]
        }
      ]
    };
  },
  watch: {
    prizeListVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updatePrizeListVisible(bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    updatePrizeListVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:prizeListVisible', bool);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/agreeTnc.scss';
@import '@/assets/css/components/promotion/blueHeadTable.scss';

/deep/ .el-dialog__body {
  padding-top: 20px !important;
}
/deep/ .header-text {
  margin-bottom: 16px !important;
}
.text-uppercase {
  text-transform: uppercase;
}

/deep/ .el-dialog__body .top li {
  padding-bottom: 20px !important;
}

.common-dialog-wrapper /deep/ {
  .dialog-body {
    padding: 0 0 0 0;
  }
}

/deep/ .el-table {
  th {
    font-size: 12px;
  }
}
/deep/ .el-table.desc-table th:nth-child(1) .cell {
  border-top-right-radius: 0;
  border-top-left-radius: 8px;
}
/deep/ .el-table.desc-table th:nth-child(2) .cell {
  border-top-right-radius: 8px;
  border-top-left-radius: 0;
  border-left: 0.1px solid $white !important;
}

.deposit-container {
  display: flex;
  justify-content: space-around;
}
.gifts-container {
  font-size: 12px;
  text-align: left;
  margin-left: 8px;
}

@media (min-width: 640px) {
  /deep/ .el-table {
    th {
      font-size: 15px;
    }
  }
}
</style>
