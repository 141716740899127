<template>
  <section id="xmasMakeAWish" v-cloak>
    <!-- banner -->
    <div class="banner-container">
      <div class="new-banner-image" :style="{ backgroundImage: `url(${rwdBannerBg})` }">
        <BannerItemV1 v-bind="banner" :lang="imgLang" />
      </div>
    </div>

    <!-- main -->
    <div class="content-box">
      <div class="inner">
        <div class="inner-container">
          <FundAccount
            :optIn="optIn"
            :participantStatus="participantStatus"
            :canSpin="canSpin"
            :currentPrize="currentPrize"
            :currentDepositLotsData="currentDepositLotsData"
            @activeOptIn="init"
          />

          <!-- tables -->
          <div class="table-wrapper">
            <div class="table-item" v-if="canSpin > 0">
              <CurrentDepositLotsTable :tableData="[currentDepositLotsData]" />
            </div>
            <div class="table-item">
              <TransactionTable :tableData="transactionHistory" :account="getFormatParticipateAccount" />
            </div>
            <div class="table-item" v-if="canSpin > 0">
              <GiftRedemptionTable :tableData="[giftRedemptionData]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- dialogs -->
    <CallbackDialog
      :show.sync="resultDialog"
      :callbackType="optStatus"
      @optout="optSignOut"
      @closCbDialog="actionClosCbDialog"
    />
  </section>
</template>

<script>
import FundAccount from '@/components/promotion/xmasMakeAWish/FundAccount.vue';
import CurrentDepositLotsTable from '@/components/promotion/xmasMakeAWish/tables/CurrentDepositLotsTable.vue';
import TransactionTable from '@/components/promotion/xmasMakeAWish/tables/TransactionTable.vue';
import GiftRedemptionTable from '@/components/promotion/xmasMakeAWish/tables/GiftRedemptionTable.vue';
import BannerItemV1 from '@/components/banner/item/v1/BannerItem.vue';
import CallbackDialog from '@/components/promotion/xmasMakeAWish/CallbackDialog.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { apiOptOutWithAccount } from '@/resource';
import { isSuccess } from '@/util/restApi';

export default {
  name: 'XmasWishAMake',
  components: {
    FundAccount,
    CurrentDepositLotsTable,
    TransactionTable,
    GiftRedemptionTable,
    BannerItemV1,
    CallbackDialog
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      banner: null
    };
  },
  computed: {
    ...mapState('promotion/xmasMakeAWish', [
      'optIn',
      'participantStatus',
      'participateAccount',
      'canSpin',
      'currentPrize',
      'transactionHistory',
      'giftRedemptionData',
      'currentDepositLotsData',
      'canCreditRedeem',
      'canVoucherRedeem',
      'showResultDialog',
      'callbackStatus',
      'showLuckyDrawDialog',
      'luckyDrawType',
      'startLuckyDraw'
    ]),
    ...mapGetters('promotion/xmasMakeAWish', ['campaignID', 'getOptParticipantStatus', 'getFormatParticipateAccount']),
    resultDialog: {
      get() {
        return this.showResultDialog;
      },
      set(newValue) {
        this.actionSetShowResultDialog(newValue);
      }
    },
    optStatus: {
      get() {
        return this.callbackStatus;
      },
      set(newValue) {
        this.actionSetCallbackStatus(newValue);
      }
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    rwdBannerBg() {
      if (this.banner?.img) {
        if (this.windowWidth <= 414) {
          return this.banner?.imgSmall;
        }
        return this.banner?.img;
      }

      return null;
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions('promotion/xmasMakeAWish', [
      'actionFetchCampaignInfo',
      'actionSetShowResultDialog',
      'actionSetCallbackStatus',
      'actionSetOptnIn',
      'actionClosCbDialog'
    ]),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    init() {
      this.getBannerSetting();

      if (!this.campaignID) {
        this.$router.push('/404');
      } else {
        this.actionFetchCampaignInfo(this.campaignID);
      }
    },
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      this.banner = { ...regulatorConfig };
    },
    async optSignOut() {
      try {
        const q = {
          campaignId: this.campaignID,
          accountMt4Dto: this.participateAccount
        };

        const { data: cbData } = await apiOptOutWithAccount(q);

        if (isSuccess(cbData)) {
          // code === 0 means opt-out success
          await this.$store.dispatch('promotion/actionGetEligibleCampaigns');

          this.actionSetCallbackStatus('failOptout');
          await this.init();
          this.actionSetOptnIn(false);

          this.actionSetShowResultDialog(true);
        } else {
          this.actionClosCbDialog();
          return this.showError();
        }
      } catch (e) {
        this.actionClosCbDialog();
        return this.showError();
      }
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 3000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/xmasMakeAWish/xmasMakeAWish.scss';
</style>
