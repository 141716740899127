<template>
  <section class="mb-24">
    <!-- description -->
    <div class="mb-24">
      <p v-for="(desc, index) in description" :key="index" v-html="$t(desc)" />
    </div>
    <div class="start-container">
      <div class="prize text-white">
        <!-- comming soon -->
        <div v-if="canSpin === 3" class="context-container">
          <p class="prize-general-title">{{ $t('promotion.xmasMakeAWish.luckyDraw.commingSoon.title') }}</p>
          <p class="prize-general-content">{{ $t('promotion.xmasMakeAWish.luckyDraw.commingSoon.content') }}</p>
        </div>

        <!-- lucky draw -->
        <div v-if="canSpin === 1" class="main-container">
          <!-- can draw -->
          <div v-if="currentPrize.currentPrizeList.length">
            <div>
              <p class="prize-title" :class="[lang]">{{ $t('promotion.xmasMakeAWish.luckyDraw.mainTitle') }}</p>
              <ol>
                <li
                  v-for="(item, index) in currentPrize.currentPrizeList"
                  :key="index"
                  :class="{ 'plenty-prizes': currentPrize.currentPrizeList.length > 3 }"
                >
                  {{ $t(item) }}
                </li>
              </ol>
            </div>

            <div class="button-box">
              <el-button class="el-button btn-blue" @click="askToDraw" data-testid="enterLuckyDraw">
                {{ $t('promotion.xmasMakeAWish.button.enterLuckyDraw') }}
              </el-button>
            </div>
          </div>
          <!-- no chance to draw -->
          <div v-else class="context-container">
            <p class="prize-general-title">{{ $t('promotion.xmasMakeAWish.luckyDraw.noChanceToDraw.title') }}</p>
            <p class="prize-general-content">{{ $t('promotion.xmasMakeAWish.luckyDraw.noChanceToDraw.content') }}</p>
          </div>
        </div>

        <!-- end promotion -->
        <div v-if="canSpin === 2" class="context-container">
          <p class="prize-general-title">{{ $t('promotion.xmasMakeAWish.luckyDraw.endPromotion.title') }}</p>
          <p class="prize-general-content">{{ $t('promotion.xmasMakeAWish.luckyDraw.endPromotion.content') }}</p>
        </div>
      </div>
      <img
        class="hat-image"
        :class="{ ballAT: startAnimation }"
        src="@/assets/images/promotion/luckyDraw/hat.png"
        alt="hat"
      />
    </div>
  </section>
</template>

<script>
import { apiSpinTheWheel } from '@/resource';
import { isSuccess } from '@/util/restApi';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LuckyDraw',
  props: {
    startLuckyDraw: {
      type: Boolean,
      default: false
    },
    canSpin: {
      type: Number,
      default: 0
    },
    currentPrize: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      description: this.$t('promotion.xmasMakeAWish.luckyDraw.description'),
      startAnimation: false
    };
  },
  watch: {
    startLuckyDraw: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.canSpin === 1) this.goSpinTheWheel();
      }
    }
  },
  computed: {
    ...mapGetters('promotion/xmasMakeAWish', ['campaignID']),
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    ...mapActions('promotion/xmasMakeAWish', ['actionSetPrize']),
    askToDraw() {
      this.$emit('openLuckyDrawDialog', 'confirm');
    },
    async goSpinTheWheel() {
      this.startAnimation = true;
      try {
        const { data: result } = await apiSpinTheWheel({
          campaignId: this.campaignID,
          groupId: this.currentPrize.groupId
        });

        if (isSuccess(result)) {
          this.actionSetPrize(result?.data || '');

          let _this = this;
          setTimeout(function() {
            _this.startAnimation = false;
            _this.$emit('luckyDrawOver', 'success');
          }, 2000);
        } else {
          // api exist but api return error (code !== 0)
          this.startAnimation = false;
          this.$message({
            message: this.$t('common.field.failed'),
            type: 'error',
            duration: 3000
          });
          this.$emit('luckyDrawOver');
        }
      } catch (error) {
        // api no exist or other exceptions
        this.startAnimation = false;
        this.$message({
          message: this.$t('common.field.failed'),
          type: 'error',
          duration: 3000
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/xmasMakeAWish/luckyDraw.scss';
</style>
